<template>
  <div id="body">
    <div class="chat-container">
      <div class="chat-header">AI Tutor</div>
      <div
        class="chat-box"
        id="chat-box"
        style="font-family: notosansregular"
      ></div>
      <div class="chat-input-container">
        <input
          @keyup.enter="send()"
          v-model="mention"
          type="text"
          id="chat-input"
          class="chat-input"
          placeholder="메시지를 입력하세요..."
        />
        <button class="send-button" @click="send()">SEND</button>
      </div>
    </div>
  </div>
</template>

<script>
import usertalk from "./Usertalk.vue";
import aitalk from "./AItalk2.vue";
import { getTutorTopics, getTutorPrompt } from "../api/api-list";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "HelloWorld",
  data() {
    return {
      mention: "",
      default_mention: "gdfndgng",
      talk_session: [],
      talk_session_temp: [],
      talk_prompt: "",
      hint_prompt: "",
      scrollTarget: null,
      conversation_list: [],
      topics: [],
      tutorTitle: "",
      tutorPrompt: "",
      session_id: "",
      session_date: "",
      mentionIndex: 0,
      intervalId: "",
      index: 0,
      endSentence: "",
    };
  },
  async created() {
    try {
      const session_id = this.$route.params.sessionId;
      const { prompt } = (await getTutorPrompt(session_id)).data;
      this.session_id = uuidv4();
      this.session_date = this.prettydate();

      if (prompt === "error") {
        alert("만료된 대화 세션입니다.");
        this.$router.replace("/error");
      } else {
        const endRes = await getEndSentence();
        this.endSentence = endRes.data.message[0];

        this.tutorPrompt = prompt;
        this.talk_session = [{ role: "system", content: prompt }];
        this.talk_session_temp = [{ role: "system", content: prompt }];

        const startSession = [
          { role: "system", content: prompt },
          { role: "user", content: "시작" },
        ];
        const { aitalk, text } = await this.$store.dispatch(
          "GET_AITUTOR_TALK2",
          {
            session: startSession,
            session_id: this.$route.params.sessionId,
            userId: `${this.session_date}-${this.session_id}`,
          }
        );
        this.talk_session.push({ role: "assistant", content: text });
        this.talk_session_temp.push({ role: "assistant", content: text });

        this.receiveMessage(aitalk);
      }
    } catch (err) {
      console.log(err);
    }
  },
  mounted() {
    this.scrollTarget = document.getElementById("scroll-target");
  },
  // updated() {
  //   this.scrollTarget.scrollTo({ top: this.scrollTarget.scrollHeight });
  // },
  methods: {
    isMobile() {
      return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    // goScroll() {
    //   this.scrollTarget.scrollTo({ top: this.scrollTarget.scrollHeight });
    // },
    async send() {
      if (!this.tutorPrompt) {
        window.alert("프롬프트가 없습니다.");
        return;
      }

      if (!this.mention) {
        return;
      }

      this.talk_session.push({ role: "user", content: this.mention });
      this.talk_session_temp.push({ role: "user", content: this.mention });

      this.displayMessage(this.mention, "sent");
      const chatBox = document.getElementById("chat-box");
      chatBox.scrollTop = chatBox.scrollHeight;

      this.mention = "";
      const { aitalk, text } = await this.$store.dispatch("GET_AITUTOR_TALK2", {
        session: this.talk_session,
        session_id: this.$route.params.sessionId,
        userId: `${this.session_date}-${this.session_id}`,
      });
      this.talk_session.push({ role: "assistant", content: text });
      this.talk_session_temp.push({ role: "assistant", content: text });

      this.receiveMessage(aitalk);
    },
    displayMessage(message, type) {
      const chatBox = document.getElementById("chat-box");
      const messageElement = document.createElement("div");
      messageElement.className = `chat-message ${
        type === "sent" ? "sent-message" : "received-message"
      }`;

      if (type === "received") {
        messageElement.classList.add(`text${this.mentionIndex}`);
      }
      messageElement.textContent = message;

      chatBox.appendChild(messageElement);
    },
    receiveMessage(message) {
      this.displayMessage(message, "received");
      const chatBox = document.getElementById("chat-box");
      chatBox.scrollTop = chatBox.scrollHeight;
    },
    typing(content) {
      this.$emit("typingStart");
      const text = document.querySelector(`.text${this.mentionIndex}`);

      if (this.index < content.length) {
        let txt = content[this.index++];
        text.innerHTML += txt === "\n" ? "<br/>" : txt;
      } else {
        // 모든 텍스트가 출력되었을 때 setInterval을 종료
        clearInterval(this.intervalId);
        this.mentionIndex++;
      }
    },
    getTutorPrompt() {
      this.session_id = uuidv4();
      this.session_date = this.prettydate();
      this.talk_session = [{ role: "system", content: this.tutorPrompt }];
      this.talk_session_temp = [{ role: "system", content: this.tutorPrompt }];
    },
    prettydate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const second = date.getSeconds();

      return `${year}-${this.gettwonum(month)}-${this.gettwonum(
        day
      )} ${this.gettwonum(hour)}:${this.gettwonum(minutes)}:${this.gettwonum(
        second
      )}`;
    },
    gettwonum(str) {
      return str.toString().padStart(2, "0");
    },
    async classEnd() {
      const endSentence = this.endSentence;

      const { aitalk, text } = await this.$store.dispatch("GET_AITUTOR_TALK2", {
        session: [...this.talk_session, { role: "user", content: endSentence }],
        session_id: this.$route.params.sessionId,
        userId: `${this.session_date}-${this.session_id}`,
      });

      this.talk_session.push({ role: "assistant", content: text });
      this.talk_session_temp.push({ role: "assistant", content: text });

      this.receiveMessage(aitalk);
    },
  },
  components: {
    usertalk,
    aitalk,
  },
};
</script>

<style>
#body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f7f7e7;
  font-family: Arial, sans-serif;
}

.chat-container {
  width: 100%;
  max-width: 400px;
  height: 90vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #1e88e5;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
}

.chat-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  border-bottom: 1px solid #ddd;
}

.chat-message {
  padding: 8px;
  margin: 5px 0;
  border-radius: 5px;
  max-width: 80%;
  word-wrap: break-word;
}

.sent-message {
  background-color: #ffe57d;
  margin-left: auto;
  text-align: right;
}

.received-message {
  background-color: #b2eeff;
  margin-right: auto;
  text-align: left;
}

.chat-input-container {
  display: flex;
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.send-button {
  padding: 10px 15px;
  margin-left: 10px;
  background-color: #1e88e5;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #1565c0;
}
</style>