import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeComponent from '@/components/HomeComponent.vue'
import Aitutor from '@/components/aitutor.vue'
import aitutormobile from '@/components/aitutormobile.vue'
import Aitutor2 from '@/components/aitutor2.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent
  },
  {
    path: '/talk/:sessionId',
    name: 'chat',
    component: Aitutor
  },
  {
    path: '/m/talk/:sessionId',
    name: 'mobile chat',
    component: aitutormobile
  },
  {
    path: '/inner/talk/:sessionId',
    name: 'innerchat',
    component: Aitutor2
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
