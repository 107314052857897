import {
  talk,
  talk2,
  feedback,
  hint,
  clovatalk,
  clovafeedback,
  clovahint
} from '../api/api-list'

const SESSION = async ({ commit }, data) => {
  await commit('SET_SESSION', data)
}

const GET_AITALK = async ({ commit }, data) => {
  const info = await talk(data)
  return info.data.message
}

const GET_AITUTOR_TALK = async ({ commit }, data) => {
  const info = await talk(data)
  const first = info.data.message.split('\n\n')

  for (let i = 0; i < first.length; i++) {
    first[i] = first[i].split('\n')
  }

  return {
    aitalk: flatmap(first),
    text: info.data.message
  }
}

const GET_AITUTOR_TALK2 = async ({ commit }, data) => {
  const info = await talk2(data)
  const first = info.data.message.split('\n\n')

  for (let i = 0; i < first.length; i++) {
    first[i] = first[i].split('\n')
  }

  return {
    aitalk: flatmap(first),
    text: info.data.message
  }
}

const flatmap = (arr) => {
  const result = [].concat(...arr)

  return result
}

const GET_AIFEEDBACK = async ({ commit }, data) => {
  const info = await feedback(data)
  return info.data.message
}

const GET_HINT = async ({ commit }, data) => {
  const info = await hint(data)
  return info.data.message
}

const GET_CLOVA_TALK = async ({ commit }, data) => {
  const info = await clovatalk(data)
  return info.data.message
}

const GET_CLOVA_FEEDBACK = async ({ commit }, data) => {
  const info = await clovafeedback(data)
  return info.data.message
}

const GET_CLOVA_HINT = async ({ commit }, data) => {
  const info = await clovahint(data)
  return info.data.message
}

export {
  SESSION,
  GET_AITALK,
  GET_AITUTOR_TALK,
  GET_AITUTOR_TALK2,
  GET_AIFEEDBACK,
  GET_HINT,
  GET_CLOVA_TALK,
  GET_CLOVA_FEEDBACK,
  GET_CLOVA_HINT
}
