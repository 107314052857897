<template>
  <div class="background">
    <div
      style="
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
      "
    >
      <v-row style="" justify="center">
        <v-card
          outlined
          width="800"
          height="750"
          style="
            border-radius: 15px;
            border: 1px solid rgb(240, 240, 240);
            transform: scale(0.85);
          "
        >
          <header>
            <v-card-title
              style="
                background-color: #1976d2;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                color: white;
              "
            >
              AI Tutor
            </v-card-title>
          </header>
          <v-row style="margin-top: 10px" justify="center">
            <v-card
              id="scroll-target"
              outlined
              width="780"
              height="600"
              style="overflow-y: scroll; overflow-x: hidden"
            >
              <div
                v-for="(talk, i) in talk_session_temp"
                :key="i"
                style="width: 700px; padding: 15px"
              >
                <usertalk
                  v-if="talk_session[i].role == 'user'"
                  style="margin-left: 220px; margin-top: 15px"
                  :_mention="talk_session[i].content"
                ></usertalk>
                <aitalk
                  :class="{
                    tableOn: talk.isTable === true,
                    tableOff: talk.isTable === false,
                  }"
                  @typingStart="goScroll()"
                  v-if="talk_session[i].role == 'assistant'"
                  style="margin-top: 15px"
                  :_talkIndex="i"
                  :_mention="talk_session_temp[i].content"
                  :_usermention="talk_session[i - 1].content"
                  :_hintprompt="hint_prompt"
                  :isTable="talk_session_temp[i].isTable"
                ></aitalk>
              </div>
            </v-card>
          </v-row>
          <v-row justify="center">
            <div style="width: 500px; margin-top: 10px; margin-left: 20px">
              <v-text-field
                :disabled="!isSend"
                @keyup.enter="send()"
                outlined
                v-model="mention"
              ></v-text-field>
            </div>
            <v-btn
              outlined
              :disabled="!isSend"
              @click="send()"
              style="
                margin-left: 8px;
                margin-top: 20px;
                background-color: #1976d2;
                color: white;
                font-family: notosansregular;
              "
              >SEND</v-btn
            >
            <v-btn
              :disabled="!isSend"
              outlined
              @click="classEnd()"
              style="
                margin-left: 8px;
                margin-top: 20px;
                background-color: green;
                color: white;
                font-family: notosansregular;
              "
              >수업 종료</v-btn
            >
          </v-row>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import usertalk from "../components/Usertalk.vue";
import aitalk from "../components/AItalk2.vue";
import {
  getTutorTopics,
  getTutorPrompt,
  getEndSentence,
} from "../api/api-list";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "HelloWorld",
  data() {
    return {
      mention: "",
      default_mention: "gdfndgng",
      talk_session: [],
      talk_session_temp: [],
      talk_prompt: "",
      hint_prompt: "",
      scrollTarget: null,
      conversation_list: [],
      topics: [],
      tutorTitle: "",
      tutorPrompt: "",
      session_id: "",
      session_date: "",
      endSentence: "",
      isSend: true,
    };
  },
  async created() {
    const session_id = this.$route.params.sessionId;
    if (this.isMobile()) {
      window.location.href = `${window.location.origin}/m/talk/${session_id}`;
      return;
    }
    const { prompt } = (await getTutorPrompt(session_id)).data;

    if (prompt === "error") {
      alert("만료된 대화 세션입니다.");
      this.isSend = false;
      //this.$router.replace("/");
    } else {
      this.tutorPrompt = prompt;
      const talkTempSession = [
        { role: "system", content: prompt },
        { role: "user", content: "시작" },
      ];

      this.talk_session = [{ role: "system", content: prompt }];
      this.talk_session_temp = [{ role: "system", content: prompt }];

      const { aitalk, text } = await this.$store.dispatch("GET_AITUTOR_TALK2", {
        session: talkTempSession,
        session_id: this.$route.params.sessionId,
        userId: `${this.session_date}-${this.session_id}`,
      });

      this.talk_session.push({ role: "assistant", content: text });
      this.talk_session_temp.push({
        role: "assistant",
        content: text,
        isTable: false,
      });
      //this.topics = (await getTutorTopics()).data.result;
      this.session_id = uuidv4();
      this.session_date = this.prettydate();

      const endRes = await getEndSentence();
      this.endSentence = endRes.data.message[0];
    }
  },
  mounted() {
    this.scrollTarget = document.getElementById("scroll-target");
  },
  updated() {
    this.scrollTarget.scrollTo({ top: this.scrollTarget.scrollHeight });
  },
  methods: {
    isMobile() {
      return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    goScroll() {
      this.scrollTarget.scrollTo({ top: this.scrollTarget.scrollHeight });
    },
    async send() {
      if (!this.tutorPrompt) {
        window.alert("프롬프트가 없습니다.");
        return;
      }

      if (!this.mention) {
        return;
      }

      this.talk_session.push({ role: "user", content: this.mention });
      this.talk_session_temp.push({
        role: "user",
        content: this.mention,
        isTable: false,
      });

      this.mention = "";
      const { aitalk, text } = await this.$store.dispatch("GET_AITUTOR_TALK2", {
        session: this.talk_session,
        session_id: this.$route.params.sessionId,
        userId: `${this.session_date}-${this.session_id}`,
      });
      this.talk_session.push({ role: "assistant", content: text });
      this.talk_session_temp.push({
        role: "assistant",
        content: text,
        isTable: false,
      });
    },
    getTutorPrompt() {
      this.session_id = uuidv4();
      this.session_date = this.prettydate();
      this.talk_session = [{ role: "system", content: this.tutorPrompt }];
      this.talk_session_temp = [{ role: "system", content: this.tutorPrompt }];
    },
    prettydate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const second = date.getSeconds();

      return `${year}-${this.gettwonum(month)}-${this.gettwonum(
        day
      )} ${this.gettwonum(hour)}:${this.gettwonum(minutes)}:${this.gettwonum(
        second
      )}`;
    },
    gettwonum(str) {
      return str.toString().padStart(2, "0");
    },
    async classEnd() {
      const endSentence = this.endSentence;

      const { aitalk, text } = await this.$store.dispatch("GET_AITUTOR_TALK2", {
        session: [...this.talk_session, { role: "user", content: endSentence }],
        session_id: this.$route.params.sessionId,
        userId: `${this.session_date}-${this.session_id}`,
      });

      const markdown = require("markdown-it")();
      let htmlContent = markdown.render(text);
      htmlContent = htmlContent.replace(
        "<table>",
        '<table style="width: 580px; border: 1px solid black; border-collapse: collapse;">'
      );
      htmlContent = htmlContent.replace(
        /<th>/g,
        '<th style="border: 1px solid black; min-width: 100px; padding: 5px">'
      );
      htmlContent = htmlContent.replace(
        /<td>/g,
        '<td style="border: 1px solid black; min-width: 100px; padding: 5px">'
      );

      this.talk_session.push({ role: "assistant", content: htmlContent });
      this.talk_session_temp.push({
        role: "assistant",
        content: htmlContent,
        isTable: true,
      });

      return;
    },
  },
  components: {
    usertalk,
    aitalk,
  },
};
</script>

<style>
.background {
  background-color: beige;
  height: 100vh;
}

.tableOn {
  margin-left: -20px;
}

.tableOff {
  margin-left: -170px;
}
</style>
